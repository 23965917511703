(function () {
  /* global _ */
  'use strict';

  /**
   * @ngdoc service
   * @name neo.security.factory:Acl
   *
   * @description
   *
   */
  angular
  .module('neo.security')
  .factory('AclService', AclService);

  function AclService() {
    var AclBase = {};
    AclBase.can = can;
    AclBase.setFeatureAcls = setFeatureAcls;
    AclBase.setRoles = setRoles;
    AclBase.isAdmin = isAdmin;

    // ------------------------

    function can(name, acl) {
      return _.some(AclBase.featureAcls, function (featureAcl) {
        return featureAcl.name === name && _.includes(featureAcl.permissions, acl);
      });
    }

    function setFeatureAcls(featureAcl) {
      AclBase.featureAcls = featureAcl;
    }

    function setRoles(roles) {
      AclBase.roles = roles;
    }

    function hasRole(role) {
      return _.contains(AclBase.roles, role);
    }

    function isAdmin() {
      return hasRole('ADMIN');
    }

    return AclBase;
  }
}());
